<template>
  <div class="m_lives_main">
    <header>
      <div class="toolbar">
        <div class="cat_box">
          <div class="cat" :class="{ active: activeCat === 0 }" @click="mySetActiveCat(0)">
            <SvgIcon name="all" :size="16" />
            <span class="name">{{ $t('lives.all') }}</span>
          </div>
          <div
            v-for="cat in activeSportCategories"
            :key="cat"
            class="cat"
            :class="{ active: activeCat === cat }"
            @click="mySetActiveCat(cat)"
          >
            <SvgIcon :name="getCatSvg(cat)" :size="16" />
            <span class="name">{{ cats[cat].Name }}</span>
          </div>
        </div>
      </div>
    </header>
    <div class="function_box">
      <div class="search-container">
        <div class="search-wrapper" :class="{ 'is-expanded': isExpanded }">
          <el-input
            v-show="isExpanded"
            v-model="searchText"
            :placeholder="$t('lives.searchPlaceholder')"
            class="search-input"
            ref="input"
          >
            <template #suffix>
              <div :class="['button-icon', isExpanded ? 'is-expanded' : '']" @click="toggleSearch">
                <SvgIcon name="search" :size="14" />
              </div>
            </template>
          </el-input>
          <div v-if="!isExpanded" class="button-icon" @click="toggleSearch">
            <SvgIcon name="search" :size="14" />
          </div>
        </div>
      </div>
      <div class="button-icon" @click="refreshData">
        <SvgIcon name="refresh" :size="14" />
      </div>
      <el-dropdown v-model="currentSort" trigger="click" @command="handleCommand">
        <!-- 觸發按鈕 -->
        <div class="button-icon">
          <SvgIcon name="sort" :size="14" />
        </div>

        <!-- 下拉選單 -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="hot">{{ $t('lives.hotSort') }}</el-dropdown-item>
          <el-dropdown-item divided command="time">{{ $t('lives.timeSort') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="button-icon" @click="dialogVisible = true">
        <SvgIcon name="date" :size="14" />
      </div>
    </div>
    <main>
      <el-row :gutter="9" class="card_container">
        <el-col v-for="live in newActiveLives" :key="live.EvtID" :span="12">
          <el-card class="live-card mb-3">
            <div class="live_box" @click="showLive(live)">
              <img :src="require(`@/assets/lives/img_${getCatImg(live.CatID)}.png`)" alt="" />
              <div class="league_title">
                <SvgIcon :name="getCatSvg(live.CatID)" color="#00A8BF" :size="14" />
                <span>{{ live.CatName }} - {{ live.LeagueName }}</span>
              </div>
              <div v-if="live.IsRun" class="live_broadcast">
                <SvgIcon name="live" color="#095A5F" />
                <span>{{ $t('lives.now') }}</span>
              </div>
              <div v-if="live.TimeAct" class="time">
                <span>{{ live.TimeAct }}</span>
              </div>
            </div>
            <div class="info_box">
              <div class="date">
                <span>{{ live.ScheduleTimeStr.split(' ')[0] }}</span>
                <span>{{ live.ScheduleTimeStr.split(' ')[1].substr(0, 5) }}</span>
              </div>
              <div class="team">
                <div class="home_team">
                  <div class="name_box">
                    <span class="team_color"></span>
                    <span class="team_name">{{ live.HomeTeamStr }}</span>
                  </div>
                  <div class="score">{{ live.HomeScore }}</div>
                </div>
                <div class="away_team">
                  <div class="name_box">
                    <span class="team_color"></span>
                    <span class="team_name">
                      {{ live.AwayTeamStr }}
                    </span>
                  </div>
                  <div class="score">{{ live.AwayScore }}</div>
                </div>
              </div>
            </div>
            <el-button round @click="openTable(live)" class="btn_bet">
              {{ $t('lives.bet') }}
            </el-button>
          </el-card>
        </el-col>
      </el-row>
    </main>
    <el-dialog
      :title="$t('lives.listTitle')"
      :visible.sync="dialogVisible"
      custom-class="mat-dialog"
      :modal-append-to-body="false"
    >
      <!-- 頂部篩選器 -->
      <div class="filter-container">
        <el-select v-model="sportSelectedType" :placeholder="$t('lives.type')" size="small">
          <el-option
            v-for="option in sportTypeOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </div>

      <el-table :data="filteredLiveGames" style="width: 100%" max-height="386" :show-header="true">
        <el-table-column prop="ScheduleTimeStr" :label="$t('lives.time')" width="48" align="center">
          <template #default="{ row }">
            <div>{{ row.ScheduleTimeStr.split(' ')[1].substr(0, 5) }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="CatName" :label="$t('lives.sport')" width="58" align="center">
        </el-table-column>

        <el-table-column
          prop="LeagueName"
          :label="$t('lives.league')"
          align="center"
          min-width="150"
        >
        </el-table-column>

        <el-table-column
          prop="teams"
          :label="$t('lives.teams')"
          header-align="center"
          min-width="150"
        >
          <template #default="{ row }">
            <div class="team-column">
              <div>{{ row.HomeTeamStr }}</div>
              <div>{{ row.AwayTeamStr }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import SvgIcon from '@/components/SvgIcon.vue';
  import { getCatName } from '@/utils/gameHelper';

  export default {
    components: {
      SvgIcon,
    },
    data() {
      return {
        catOptionsShow: false,
        isExpanded: false,
        searchText: '',
        dialogVisible: false,
        selectedType: 'all',
        sportSelectedType: 'all',
      };
    },
    computed: {
      ...mapState(['siteLabel']),
      ...mapState('Lives', ['cats', 'activeCats', 'activeCat', 'lives', 'sortType']),
      ...mapGetters('Lives', ['activeLives']),
      sportTypeOptions() {
        // 取得所有運動類別名稱並去重
        const uniqueSports = [...new Set(this.lives.map((item) => item.CatName))];

        // 轉換成 options 格式
        return [
          { label: this.$t('lives.all'), value: 'all' },
          ...uniqueSports.map((sportType) => ({
            label: sportType,
            value: sportType,
          })),
        ];
      },
      filteredLiveGames() {
        if (this.sportSelectedType === 'all') {
          return this.lives;
        }
        return this.lives.filter((item) => item.CatName === this.sportSelectedType);
      },
      activeSportCategories() {
        const uniqueLives = [
          ...new Set(this.lives.filter((item) => item.IsRun).map((item) => item.CatID)),
        ];

        return uniqueLives;
      },
      columnSpan() {
        if (this.windowWidth <= 1200) return 12;
        if (this.windowWidth <= 1500) return 8;
        return 6;
      },
      currentSort: {
        get() {
          return this.sortType;
        },
        set(value) {
          this.setSort(value);
        },
      },
      newActiveLives() {
        if (!this.activeLives) return [];

        return this.activeLives
          .filter((item) => {
            // 先過濾運行中的賽事
            const isRunning = item.IsRun;

            // 如果有搜尋文字則加入搜尋條件
            if (this.searchText) {
              const searchLower = this.searchText.toLowerCase();

              return (
                isRunning &&
                (item.LeagueName.toLowerCase().includes(searchLower) ||
                  item.HomeTeamStr.toLowerCase().includes(searchLower) ||
                  item.AwayTeamStr.toLowerCase().includes(searchLower))
              );
            }

            return isRunning;
          })
          .sort((a, b) => {
            // 熱門排序
            if (this.sortType === 'hot') {
              if (a.IsHot !== b.IsHot) {
                return b.IsHot - a.IsHot;
              }
            }
            // 時間排序
            return new Date(a.ScheduleTime) - new Date(b.ScheduleTime);
          });
      },
    },
    methods: {
      ...mapMutations('Lives', ['setActiveCat', 'showLive', 'setSort']),
      ...mapActions('Lives', ['openHome', 'openTable']),
      showCatOptions() {
        this.catOptionsShow = true;
      },
      hideCatOptions(event) {
        if (event.target === this.$refs.cat_options_bg) {
          this.catOptionsShow = false;
        }
      },
      mySetActiveCat(cat) {
        this.setActiveCat(cat);
        this.catOptionsShow = false;
      },
      toggleSearch() {
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
          this.$nextTick(() => {
            this.$refs.input.focus();
          });
        } else {
          this.searchText = '';
        }
      },
      handleCommand(command) {
        if (command === this.sortType) return;
        // 處理選單點擊事件
        this.setSort(command);
      },
      getCatSvg(catID) {
        return getCatName(catID);
      },
      getCatImg(catID) {
        return getCatName(catID);
      },
      refreshData() {
        this.$emit('initLives');
      },
    },
  };
</script>

<style lang="scss">
  @mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .m_lives_main {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    color: #868686;
    background-color: #e3ebee;

    > header {
      position: relative;
      z-index: 10;

      > .banner {
        width: 100%;
      }

      > .toolbar {
        @include flex-center;
        justify-content: space-between;
        gap: 30px;
        height: 52px;
        padding: 0 6px;
        background-color: #fff;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);

        > .cat_box {
          display: flex;
          align-items: center;
          gap: 8px;
          flex: 1;
          overflow-x: auto;

          > .cat {
            @include flex-center;
            gap: 8px;
            min-width: 90px;
            height: 32px;
            border: 1px solid #00a8bf;
            border-radius: 8px;
            color: #00a8bf;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            background-color: #ffffff;
            transition: all 0.3s ease;

            > .icon {
              width: 20px;
              color: #00a8bf;
            }

            &.active,
            &:hover {
              color: #fff;
              background-color: #0082ab;

              > .name {
                color: #fff;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    > .function_box {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 8px;
      padding: 0 8px;
      margin-top: 10px;

      .search-container {
        @include flex-center;
        justify-content: flex-end;
      }

      .search-wrapper {
        position: relative;
        display: flex;
        align-items: center;
      }

      .search-input {
        width: 0;
        transition: all 1s ease;
      }

      .is-expanded {
        border-radius: 0 4px 4px 0;

        .search-input {
          width: 150px;
          transition: all 1s ease;

          .el-input__inner {
            height: 30px;
            border-radius: 20px;
            background-color: #095a5f;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
            font-size: 14px;
            color: #ffffff;

            &::placeholder {
              color: #7badb0;
            }
          }

          .el-input__suffix {
            right: 0;

            .button-icon {
              background-color: transparent;
              box-shadow: none;
            }
          }
        }

        .search-icon {
          @include flex-center;
          padding: 8px;
          border-radius: 50%;
          cursor: pointer;
          background: #006d77;
        }
      }

      .button-icon {
        @include flex-center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 20px;
        font-weight: 700;
        color: #ffffff;
        cursor: pointer;
        background-color: #7badb0;

        &:hover {
          background-color: #095a5f;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }

    > main {
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
      padding: 10px 8px;
      background-color: #e3ebee;

      .card_container {
        .el-col {
          margin-bottom: 8px;
        }

        .el-card {
          margin: auto;
          border-radius: 10px;
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
        }

        .el-card__body {
          padding: 8px;

          .live_box {
            position: relative;
            height: 136px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              object-fit: cover;
            }

            .league_title {
              position: absolute;
              top: 8px;
              left: 8px;
              display: flex;
              align-items: center;
              gap: 10px;
              max-width: -webkit-fill-available;
              max-width: -moz-available;
              max-width: fill-available;
              max-width: stretch;
              width: min-content;
              height: 26px;
              padding: 0 6px;
              border-radius: 40px;
              background: rgba(0, 0, 0, 0.8);
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
              color: #ffffff;

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .live_broadcast {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              align-items: center;
              gap: 8px;
              height: 30px;
              padding: 0 8px;
              border-radius: 40px;
              background-color: #75efbc;
              font-size: 12px;
              font-weight: 700;
              line-height: 12px;
              color: #095a5f;
              white-space: nowrap;
            }

            .time {
              position: absolute;
              bottom: 8px;
              right: 8px;
              display: flex;
              align-items: center;
              gap: 10px;
              width: min-content;
              height: 24px;
              padding: 0 6px;
              border-radius: 38px;
              background: rgba(0, 0, 0, 0.8);
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
              color: #ffffff;
              white-space: nowrap;
            }
          }

          .info_box {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 8px;
            padding: 0 8px;
            margin-top: 10px;

            .date {
              display: flex;
              align-items: center;
              gap: 8px;
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
              color: #868686;
            }

            .team {
              display: flex;
              flex-direction: column;
              align-items: start;
              gap: 8px;
              width: 100%;

              .home_team,
              .away_team {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;

                .name_box {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  color: #657274;
                  overflow: hidden;

                  .team_color {
                    min-width: 14px;
                    min-height: 14px;
                    border-radius: 50%;
                  }

                  .team_name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .score {
                  min-width: 30px;
                  text-align: center;
                  color: #00a2ad;
                }
              }

              .home_team {
                .name_box {
                  .team_color {
                    background-color: #095a5f;
                  }
                }
              }

              .away_team {
                .name_box {
                  .team_color {
                    background-color: #7badb0;
                  }
                }
              }
            }
          }

          .btn_bet {
            @include flex-center;
            width: 100%;
            height: 32px;
            margin-top: 10px;
            border-width: 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: #ffffff;
            background: linear-gradient(90deg, #108df4 0%, #00bea0 100%);
          }
        }
      }
    }

    .mat-dialog {
      width: calc(100% - 48px);
      border-radius: 10px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;

      .el-dialog__header {
        @include flex-center;
        height: 46px;
        padding: 0 14px;
        margin: 0;
        background-color: #00a2ad;

        .el-dialog__title {
          color: #ffffff;
          font-weight: 700;
          font-size: 16px;
        }

        .el-dialog__headerbtn {
          top: 12px;
          right: 14px;

          .el-icon-close {
            color: #ffffff;
          }
        }

        .el-icon-close {
          font-size: 22px;
          font-weight: 700;
        }
      }

      .el-dialog__body {
        padding: 15px;

        .filter-container {
          display: flex;
          align-items: center;
          gap: 15px;
          margin-bottom: 15px;

          .el-select {
            .el-input__inner {
              max-width: 120px;
              height: 32px;
              border-radius: 8px;
              border-color: #c7c7c7;
              color: #868686;
              font-weight: 400;
              font-size: 14px;
              line-height: 14px;
            }

            .el-input__suffix {
              right: 8px;
            }

            .el-icon-arrow-up {
              color: #868686;
              font-size: 16px;
              font-weight: 700;
            }
          }
        }

        .el-table {
          thead {
            th {
              padding: 0;
              border-bottom: none !important;

              .cell {
                font-weight: 400;
                font-size: 14px;
                color: #868686;
                line-height: 14px;
              }
            }
          }
          .el-table__body-wrapper {
            padding-top: 15px;

            .el-table__row {
              .el-table__cell {
                padding: 7px 0;
                border-bottom: 2px solid #c7c7c7;

                .cell {
                  padding: 0;
                  color: #095a5f;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 12px;
                  white-space: nowrap;

                  .team-column {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .v-modal {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .el-dropdown-menu {
    &.el-popper {
      padding: 8px;
      margin-top: 5px;
      border: 1px solid #e7e7e7;
      border-radius: 8px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;

      .el-dropdown-menu__item {
        @include flex-center;
        min-width: 100px;
        height: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #868686;

        &--divided {
          margin-top: 0;
          border-color: #00a2ad;

          &::before {
            content: none;
          }
        }
      }

      .popper__arrow {
        display: none;
      }
    }
  }

  .el-select-dropdown {
    &.el-popper {
      padding: 8px;
      margin-top: 5px;
      border: 1px solid #e7e7e7;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);

      .el-select-dropdown__list {
        padding: 0;
      }

      .el-select-dropdown__item {
        @include flex-center;
        height: 30px;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #868686;

        &:not(:first-child) {
          border-top: 1px solid #00a2ad;
        }
      }
      .popper__arrow {
        display: none;
      }
    }
  }
</style>
