<template>
  <div class="page">
    <template v-if="windowWidth < 800 && windowWidth">
      <MLivesLive v-if="activeLive" />
      <MLivesMain v-else @initLives="initLives" />
    </template>
    <template v-if="windowWidth >= 800 && windowWidth">
      <LivesLive v-if="activeLive" />
      <LivesMain v-else :windowWidth="windowWidth" @initLives="initLives" />
    </template>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import LivesMain from '@/components/lives/LivesMain.vue';
  import LivesLive from '@/components/lives/LivesLive.vue';
  import MLivesMain from '@/components/lives/MLivesMain.vue';
  import MLivesLive from '@/components/lives/MLivesLive.vue';

  export default {
    components: {
      LivesMain,
      LivesLive,
      MLivesMain,
      MLivesLive,
    },
    data() {
      return {
        windowWidth: null,
        updateTimer: null,
      };
    },
    computed: {
      ...mapState('Lives', ['activeLive']),
      mobileStyle() {
        return this.windowWidth < 800;
      },
    },
    methods: {
      ...mapMutations(['showLoading', 'hideLoading']),
      ...mapActions('Lives', ['updateCats', 'updateLives']),
      updateWindowWidth() {
        this.windowWidth = window.innerWidth;
      },
      myUpdateLives() {
        this.updateTimer = setTimeout(this.updateLives, 300000);
      },
      async initLives() {
        const { showLoading, hideLoading, updateCats, updateLives } = this;
        showLoading();

        try {
          await Promise.all([updateCats(), updateLives()]);
        } finally {
          hideLoading();
          this.myUpdateLives();
        }
      },
    },
    async mounted() {
      await this.initLives();
      window.addEventListener('resize', this.updateWindowWidth);
      this.updateWindowWidth();
    },
    destroyed() {
      window.removeEventListener('resize', this.updateWindowWidth);
      if (this.updateTimer) {
        clearTimeout(this.updateTimer);
        this.updateTimer = null;
      }
    },
  };
</script>

<style lang="scss"></style>
