<template>
  <svg class="svg-icon" :width="size" :height="size" :style="svgStyle" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
  export default {
    name: 'SvgIcon',
    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: Number,
        default: 20,
      },
      color: {
        type: String,
      },
    },
    computed: {
      iconName() {
        return `#icon-${this.name}`;
      },
      svgStyle() {
        return {
          fill: this.color,
          color: this.color,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .svg-icon {
    vertical-align: middle;
  }
</style>
