export const GAME_CATEGORY_MAP = {
  soccer: [1, 32],
  basketball: [3, 16, 102],
  baseball: [4, 11, 12, 13, 14, 101],
  football: [5],
  champion: [31, 86],
  lottery: [83, 92],
  combo: [98, 100, 110],
  pingpong: [21],
  badminton: [22],
  volleyball: [23],
  billiards: [24],
  tennis: [55],
  race: [72],
  hockey: [82],
  stock: [84],
  esport: [85],
  casino: [87],
  other: [91],
  slot: [200],
};
